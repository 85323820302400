<template>
  <div>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>過濾器</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>狀態</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="statusFilter"
              :options="statusOptions"
              class="w-100"
              :reduce="(val) => val.value"
            />
          </b-col>

          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>已刪除</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="deleteFilter"
              :options="deleteOptions"
              class="w-100"
              :reduce="(val) => val.value"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" class="mb-2" style="text-align: center">
            <h3 class="mb-0">員工資料報表</h3>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="12">
            <div class="d-flex align-items-center justify-content-end">
              <b-button variant="primary" @click="exportReport">
                <span class="text-nowrap">導出Excel</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableReportColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(is_active)="data">
          <feather-icon
            v-if="data.item.is_active"
            icon="CheckIcon"
            size="18"
            class="mr-50 text-success"
          />
        </template>

        <template #cell(gender)="data">
          <span v-if="data.item.gender">{{
            data.item.gender == "m" ? "男" : "女"
          }}</span>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row v-if="false">
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useUsersList from "@/views/user/users-list/useUsersList";
import userStoreModule from "@/views/user/userStoreModule";
import { getNextCycleEndDate, dateDifferent } from "@/libs/helper";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  methods: {
    exportReport() {
      this.$swal({
        title: "確定導出Excel?",
        showCancelButton: true,
        confirmButtonText: "下載",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          this.$store
            .dispatch("app-user/exportUsers", {
              perPage: this.perPage,
              page: this.currentPage,
              sortBy: this.sortBy,
              sortDesc: this.isSortDirDesc,
              is_active: this.statusFilter,
              is_deleted: this.deleteFilter,
            })
            .then((response) => {
              // create file link in browser's memory
              const href = URL.createObjectURL(response.data);
              // create "a" HTLM element with href to file & click
              const link = document.createElement("a");
              link.href = href;
              const current_datetime = new Date();
              const formatted_date =
                current_datetime.getFullYear() +
                "-" +
                (current_datetime.getMonth() + 1) +
                "-" +
                current_datetime.getDate() +
                " " +
                current_datetime.getHours() +
                "-" +
                current_datetime.getMinutes() +
                "-" +
                current_datetime.getSeconds();
              link.setAttribute("download", "Users" + formatted_date + ".xlsx");
              document.body.appendChild(link);
              link.click();

              // clean up "a" element & remove ObjectURL
              document.body.removeChild(link);
              URL.revokeObjectURL(href);
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
  data() {
    return {
      loading: false,
      getNextCycleEndDate,
      dateDifferent,
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const isAddNewUserSidebarActive = ref(false);

    const statusOptions = [
      { label: "啟用", value: 1 },
      { label: "禁用", value: 0 },
      { label: "全部", value: null },
    ];

    const deleteOptions = ref([
      {
        label: "已刪除",
        value: 1,
      },
    ]);

    onMounted(() => {
      currentPage.value = 1;
      perPage.value = null;
    });

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      ability,
      deleteFilter,
      tableReportColumns,
    } = useUsersList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      ability,

      // Filter
      avatarText,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      deleteFilter,
      deleteOptions,
      tableReportColumns,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
